<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useListTable from '@/comp-functions/useListTable';
import useHttp from '@/comp-functions/useHttp'
import Swal from "sweetalert2";

export default {
  page: {
    title: "Customer List",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  setup () {
    
    const tableColumns = [
      { key: "name", label: 'Company Name', thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "contact_name", label: 'Contact Name', thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "contact_phone", label: 'Phone', thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "email", label: "Email", thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "address", label: "Address", thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "action",label: "Edit", tdClass: "text-center" },
      { key: "actiondelete",label: "Delete", tdClass: "text-center" }
    ]
    const urllist = 'master/customers'
    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists } = useListTable({ url: urllist })

    return {
      urllist,
      tableColumns,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    }
  },
  data() {
    return {
      title: "Customer List",
      items: [],
      filterOn: []
    };
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalList = filteredItems.length;
      this.currentPage = 1;
    },
    async dataFalse(id) {
      const { $put } = useHttp()
      $put({
         url: 'master/customer/nonactive',
        data: {id}
      })
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: 'Customer status has been delete',
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
      this.searchQuery = ''
      await this.fetchLists(this.urllist)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-button href="/masterdata/customer/add" variant="light">
              <i class="ri-add-line align-middle mr-2"></i> {{ $t('global.button.add') }}
            </b-button>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="searchQuery"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :fields="tableColumns"
                :items="itemLists"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="searchQuery"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                :busy="isBusy"
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
                class="position-relative"
              >
              <template v-slot:cell(action)="data">
                  <router-link :to="{ name: 'appsmd-customer-edit', params: {customer_id : data.item.id } }">
                                        <i class="ri-edit-2-line"></i>
                  </router-link>
                </template>
              <template v-slot:cell(actiondelete)="data">
                  <b-button  variant="danger" @click.prevent="dataFalse(data.item.id)">
                      <i class="mdi mdi-trash-can"></i>
                       </b-button>
                </template>
              </b-table>
            </div>
             <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                   Showing
                    <b-form-select class="col-sm-12 col-md-2" v-model="perPage" size="sm" :options="perPageOptions"></b-form-select>{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
                  </label>
                </div>
              </div>

              <div class="col-md-6 col-xs-12">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalList"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
                </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>